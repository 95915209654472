import { fromJS } from 'immutable';

export const initialState = fromJS({
  spotifyPlaylists: null,
  spotifyPlaylistsOffset: 0,
  totalSpotifyPlaylists: null,
 });

export default function spotifyPlaylistsReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_SPOTIFY_PLAYLISTS':
      return state.set('spotifyPlaylists', null);
    case 'GET_SPOTIFY_PLAYLISTS_SUCCESS':
      return state
        .set('spotifyPlaylists', action.playlists)
        .set('spotifyPlaylistsOffset', action.offset)
        .set('totalSpotifyPlaylists', action.total);
    default:
      return state;
  }
}
