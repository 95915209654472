import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';

import injectSaga from 'utils/redux/injectSaga';
import injectReducer from 'utils/redux/injectReducer';

import SongList from 'components/SongList';

import reducer from './reducer';
import saga from './saga';
import { 
  getAlbum,
} from './actions';

import { setSourceAlbum } from 'containers/ControlPanel/actions'
import useStyles from './styles';
import { Button, TablePagination } from '@material-ui/core';


const selectAlbum = createSelector(
  state => state.album,
  substate => substate.toJS()
);

const Album = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { tracks, trackOffset, totalTracks, loading, albumMeta } = useSelector(selectAlbum);
  
  useEffect(()=> {
    dispatch(getAlbum(id))
  }, [id])

  return (
    <div className={classes.root}>
      {loading || tracks.length === 0 
        ? <div className={classes.loading}>Loading...</div>
        : <div className={classes.innerRoot}>
          <div className={classes.outerAlbumHeader}>
            <div className={classes.innerAlbumHeader}>
              <div 
                className={classes.albumArt} 
                style={{backgroundImage:`url(${albumMeta.images[1].url})`}}
                onClick={()=>dispatch(setSourceAlbum(id, albumMeta))}
              />
              <div className={classes.albumInfo}>
                <div className={classes.albumTitle}>{albumMeta.name}</div>
                <div className={classes.albumArtists}>{albumMeta.artists.map(artist => artist.name).join(', ')}</div>
              </div>
            </div>
          </div>
          <SongList tracks={tracks}/>
          <TablePagination
            component="div"
            className={classes.pagination}
            rowsPerPageOptions={[20]}
            count={totalTracks || 0}
            rowsPerPage={20}
            page={trackOffset / 20}
            onChangePage={(event, value) => dispatch(getAlbum(id, value*20))}
          />
        </div>}
    </div>
  );
}

const withReducer = injectReducer({ key: 'album', reducer });
const withSaga = injectSaga({ key: 'album', saga });

export default compose(withReducer, withSaga)(Album);
