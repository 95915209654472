import React from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, makeStyles } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import { clearPlaylist } from 'containers/ControlPanel/actions';
import { setPlaylist } from '../../actions';

const useStyles = makeStyles({
  root: {
    height: 30,
    width: 30,
    marginLeft: 2,
  },
  icon: {
    // height: '75%',
    // width: '75%'
  },
})

const PlayButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <IconButton 
      onClick={() => dispatch(setPlaylist([]))}
      className={classes.root}
    >
      <Clear className={classes.icon}/>
    </IconButton>
  );
}

export default PlayButton;