import React from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import Save from '@material-ui/icons/SaveSharp';
import { useSelector } from 'react-redux';
import makeSelectCustomPlaylist from '../../selectors';


const useStyles = makeStyles({
  root: {
    height: 30,
    width: 30,
    marginLeft: 2,
    padding: 5
  },
  icon: {
    height: 19,
    width: 19
  },
})

const SaveButton = () => {
  const { customPlaylist: contents, name } = useSelector(makeSelectCustomPlaylist());

  const saveToFile = () => {
    let source = JSON.stringify({ contents, name });
    let filename = 'playlistName'.toLowerCase().replace(/ /g, "_") +".json";
    let contentType = "application/json;charset=utf-8;";
  
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      let blob = new Blob([decodeURIComponent(encodeURI(source))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(source);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const classes = useStyles();

  return (
    <IconButton
      onClick={saveToFile}
      className={classes.root}
    >
      <Save className={classes.icon}/>
    </IconButton>
  );
}

export default SaveButton;