import React from 'react';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      <div className={classes.header}>Welcome to Versed</div>
      <div>
        Versed is a lyric quiz platform developed independently using the Spotify and Genius APIs - visit the settings page to integrate with your personal Spotify account.
      </div>
      <br/>
      <div>
        At its simplest level, Versed asks the user to recite lyrics from memory, but the way I like to play is to compile playlists of songs and shuffle them. This way, the game becomes less focussed on getting the lyrics correct, and more on guessing the song at all through patterns of common words. It's based on a Sporcle where users create lyrics quizzes without providing a song title - instead, they'll usually tell you an artist, album, or franchise. This iteration on this trend lets you compile your own selections to randomize on, or even to use playlists you've already created on Spotify.
      </div>
      <br/>
      <div>
        The lyrics for all songs available on Versed come from the Genius API. If the lyrics are wrong on Genius, they will be wrong in the game. You can help fix mistakes by suggesting corrections on the Genius page for the song you noticed a problem in.
      </div>
    </div>
  );
}

export default Home;
