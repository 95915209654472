export const getSpotifySong = (id, push, controller) => ({ type:'GET_SPOTIFY_SONG', id, push, controller });
export const setSource = (source, meta) => ({ type: 'SET_SOURCE', source, meta });

export const setSourceAlbum = (id, meta) => ({ type: 'SET_SOURCE_ALBUM', id, meta });
export const setSourcePlaylist = (id, meta) => ({ type: 'SET_SOURCE_PLAYLIST', id, meta });

export const loadNextSong = () => ({ type: 'LOAD_NEXT_SONG' });
export const loadPreviousSong = () => ({ type: 'LOAD_PREV_SONG' });

export const getGeniusSong = (id) => ({ type: 'GET_GENIUS_SONG', id });
export const getGeniusSongSuccess = (song) => ({ type: 'GET_GENIUS_SONG_SUCCESS', song });

export const toggleShuffle = () => ({ type: 'TOGGLE_SHUFFLE' });

export const togglePause = () => ({ type: 'TOGGLE_PAUSE' });

export const forfeit = () => ({ type: 'FORFEIT' });

export const win = () => ({ type: 'WIN' });
