import { grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    background: grey[800],
    color: 'white',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  }, 
  innerRoot: {
    background: grey[700]
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerPlaylistHeader: {
    background: grey[800],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 150,
    marginBottom: 50,
  },
  innerPlaylistHeader: {
    position: 'absolute',
    bottom: '-40px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  playlistArt: {
    background: grey[800],
    borderRadius: 15,
    width: 150, 
    height: 150,
    backgroundSize: 'cover',
    border: `10px solid ${grey[800]}`,
    position: 'relative',
    transition: '.3s',
    cursor: 'pointer',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      height:0,
      width: 0,
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      borderTop: '25px solid transparent',
      borderLeft: '40px solid white',
      borderBottom: '25px solid transparent',
      transition: '.2s',
      zIndex: 1,
      opacity: 0
    }, 
    '&:hover' : {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        opacity: '0.5',
      },
      '&::before': {
        opacity: 1
      }
    }
  },
  playlistInfo: {
    '& *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    maxWidth: '25vw',
    marginLeft: 5,
    marginBottom: 45,
  }, 
  playlistTitle: {
    fontSize: 25,
    fontWeight: 600,
  },
  playlistArtists: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'italic'
  },
  pagination: {
    marginTop: 50,
    background: grey[800]
  },
});

export default useStyles;