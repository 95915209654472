import cheerio from 'cheerio';
import { takeLatest, all, put, select } from 'redux-saga/effects';
import { getRequestOptions, request } from "utils/request";
import makeSelectApp from '../App/selectors';
import { getGeniusSongSuccess, setSource } from "./actions";

export function* makeGetSpotifySongRequest(action) {
  try {
    const payload = {
      id: action.id
    }
    const response = yield request(`/api/public/song`, getRequestOptions('post', payload));
    if (!action.controller) yield put(setSource(null));
    if (response.genius) action.push(`/songs/${response.genius}`);
    else alert("We can't find the lyrics for this song!")
  } catch(err) {
  }
}

export function* makeGetGeniusSongRequest(action) {
  try {
    const token = "4qtBMiQeR5pD1zFm-vGmFV6j5khGAiRQskTCLXyuGbxeYGbnXrTnXIyA5n2iXjdg";
    const { response } = yield request(`https://api.genius.com/songs/${action.id}?access_token=${token}`, getRequestOptions('get'));
    const webpage = yield request(`https://server.amrictor.com:2083/${response.song.url}`)
    const $ = cheerio.load(webpage.replaceAll('<br/>', '\n'), { ignoreWhitespace: false })
    let song = {}
    song.title = response.song.title ? response.song.title : "N/A";
    song.album = response.song.album ? response.song.album.name : "N/A";
    song.artist = response.song.primary_artist ? response.song.primary_artist.name : "N/A";
    song.url = response.song.url;
    song.lyrics = $('div[data-lyrics-container=true]').text().replace(/\[[^\]]*\]/g, "").trim().replace(/[’‘]/g, '\'').split((/[\u200B\r\n\s,?!:;().…"“”—\-–_]+/)).filter((word) => !word.match(/^[\s']+$/) && word);
    yield put(getGeniusSongSuccess(song));
  } catch(err) {
    console.log(err)
    alert('Something went wrong. Try playing a different song')
  }
}

export function* getFullAlbum(action) {
  try {
    const { spotifyToken } = yield select(makeSelectApp());
    const payload = {
      accessToken: spotifyToken.access,
      id: action.id,
      offset: 0
    }
    let result = []
    while(payload.offset < 100) {
      const page = yield request(`/api/public/album`, getRequestOptions('post', payload));
      result = [...result, ...page.items];
      if (payload.offset > page.total) break;
      payload.offset += 20;
    }
    yield put(setSource(result, action.meta))
  } catch(err) {
    console.log(err)
  }
}

export function* getFullPlaylist(action) {
  try {
    const { spotifyToken } = yield select(makeSelectApp());
    const payload = {
      accessToken: spotifyToken.access,
      id: action.id,
      offset: 0
    }
    let result = []
    while(payload.offset < 100) {
      const page = yield request(`/api/public/playlist`, getRequestOptions('post', payload));
      result = [...result, ...page.items];
      if (payload.offset > page.total) break;
      payload.offset += 20;
    }
    yield put(setSource(result, action.meta))
  } catch(err) {
    console.log(err)
    // yield put(getAlbumSuccess([]))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest('GET_SPOTIFY_SONG', makeGetSpotifySongRequest),
    takeLatest('GET_GENIUS_SONG', makeGetGeniusSongRequest),
    takeLatest('SET_SOURCE_ALBUM', getFullAlbum),
    takeLatest('SET_SOURCE_PLAYLIST', getFullPlaylist),
  ]);
}