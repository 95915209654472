import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  emptyList: {
    padding: '100px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  song: {
    flexShrink: 1,
    padding: '3px 0px',
    display: 'flex',
    fontSize: 12,
  },
  songBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    justifyContent: 'space-between',
    '& > *': {
      minWidth: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.15)',
    }
  },
  albumCover: {
    marginRight: 5,
    height: 45,
    width: 45,
    flexShrink: 0,
  },
  trackInfo: {
    flexShrink: 1,
    minWidth: 40,
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  trackTitle: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  trackAlbum: {
    fontStyle: 'italic',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  trackArtists: {
    display: 'flex',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  trackArtist: {
    flexShrink: 0,
    fontStyle: 'italic',
    // cursor: 'pointer',
    // '&:hover': {
    //   textDecoration: 'underline',
    // }
  },
  actions: {
    display: 'flex',
    flexShrink: 0,
  }
})