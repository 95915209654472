import { takeLatest, all, select, put } from 'redux-saga/effects';

import makeSelectApp from "containers/App/selectors";
import { getRequestOptions, request } from "utils/request";

import { getSpotifyPlaylistsSuccess } from "./actions";

export function* makeGetSpotifyPlaylistsRequest(action) {
  const { spotifyToken } = yield select(makeSelectApp());
  try {
    const payload = {
      accessToken: spotifyToken.access,
      offset: action.offset
    }
    const response = yield request(`/api/public/playlists`, getRequestOptions('post', payload));
    console.log(response)
    yield put(getSpotifyPlaylistsSuccess(response.items, response.offset, response.total))
  } catch(err) {

  }
}

export default function* rootSaga() {
  yield all([
    takeLatest('GET_SPOTIFY_PLAYLISTS', makeGetSpotifyPlaylistsRequest),
  ]);
}