import { fromJS } from 'immutable';
import { getItem, setItem } from 'utils/storage'

let customPlaylist = getItem("customPlaylist");
if (!Array.isArray(customPlaylist)) {
  customPlaylist = [];
}

export const initialState = fromJS({
  customPlaylist
 });


export default function customPlaylistReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'ADD_SONG':
      if (!(action.song && action.song.id)) return state;
      newState = state.set('customPlaylist', [...state.get('customPlaylist'), action.song]); 
      break;
    case 'DELETE_SONG':
      newState = state.set('customPlaylist', state.get('customPlaylist').filter(song => song.id !== action.song.id)); 
      break;
    case 'SET_PLAYLIST':
      if (!(action.playlist && action.playlist)) return state;
      newState = state.set('customPlaylist', action.playlist);
      break;
    default:
      return state;
  }

  setItem('customPlaylist', newState.get('customPlaylist'));
  return newState;
}
