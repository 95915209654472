import React, { useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import injectSaga from 'utils/redux/injectSaga';
import injectReducer from 'utils/redux/injectReducer';

import reducer from './reducer';
import saga from './saga';
import { 
  searchTracks 
} from './actions';
import useStyles from './styles';
import { getSpotifySong } from '../ControlPanel/actions';
import { IconButton, TablePagination } from '@material-ui/core';
import { Add, SearchOutlined } from '@material-ui/icons';
import { addSong } from '../CustomPlaylist/actions';
import SongList from '../../components/SongList';

const selectSearch = createSelector(
  state => state.search,
  substate => substate.toJS()
);

const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tracks, trackOffset, totalTracks, loading } = useSelector(selectSearch);
  const [ query, setQuery ] = useState('')
  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <input placeholder="Search..." className={classes.input} value={query} onChange={(evt)=>setQuery(evt.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') dispatch(searchTracks(query, 0))}} disabled={loading}/>
        <button className={classes.button} onClick={()=>dispatch(searchTracks(query, 0))} disabled={loading}>
          <SearchOutlined/>
        </button>
      </div>
      <div className={classes.innerRoot}>
        <SongList tracks={tracks}/>
      </div>
      {tracks.length > 0 && <TablePagination
        component="div"
        className={classes.pagination}
        rowsPerPageOptions={[20]}
        count={totalTracks || 0}
        rowsPerPage={20}
        page={trackOffset / 20}
        onChangePage={(event, value) => dispatch(searchTracks(query, value*20))}
      />}
    </div>
  );
}

const withReducer = injectReducer({ key: 'search', reducer });
const withSaga = injectSaga({ key: 'search', saga });

export default compose(withReducer, withSaga)(Search);
