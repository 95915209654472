import { grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    background: grey[700],
    color: 'white',
    height: '100%',
    width: '100%',
    padding: 25,
  },
  header: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid white'
  },
  sectionHeader: {
    fontSize: 18,
    marginBottom: 5,
    marginTop: 30,
  },
  input: {
    height: 30,
    padding: 3,
    flexGrow: 1,
    outline: 'none',
    borderRadius: 8,
    border: '0px',
    boxShadow: '0px 0px #1DB954',
    transition: 'box-shadow .2s',
    '&:focus': {
      border: '0px solid black',
      boxShadow: `3px 3px #1DB954`
    },
    marginRight: 10
  },
});

export default useStyles;