import { grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: grey[900],
    color: 'white',
    height: 75,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  left: {
    flexGrow: 0,
    flexShrink: 0,
    width: '33%',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
    width: '33%',
  },
  right: {
    flexGrow: 0,
    flexShrink: 0,
    width: '33%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionBtn: {
    margin: 5,
  },
  song: {
    flexShrink: 1,
    padding: '3px 0px',
    display: 'flex',
    fontSize: 12,
  },
  songBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    justifyContent: 'space-between',
    '& *': {
      flex: '0 1 1',
      minWidth: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.4)',
    }
  },
  albumCover: {
    marginRight: 15,
    height: 45,
    width: 45,
    flexShrink: 0,
  },
  trackInfo: {
    flexShrink: 1,
    minWidth: 40,
    '& *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  trackTitle: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  trackAlbum: {
    fontStyle: 'italic',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  trackArtists: {
    display: 'flex'
  },
  trackArtist: {
    fontStyle: 'italic',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
}));

export default useStyles;