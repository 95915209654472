import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, makeStyles } from '@material-ui/core';
import { ImFolderUpload } from 'react-icons/im';

import { setPlaylist } from '../../actions';

const useStyles = makeStyles({
  root: {
    height: 30,
    width: 30,
    marginLeft: 2,
    padding: 5
  },
  icon: {
    height: 14,
    width: 14
  },
  hidden: {
    display: 'none'
  }
})

const UploadButton = () => {
  const fileInput = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const loadFile = (event) => {
    let reader = new FileReader();
    reader.onload = function(event) {
      let fileContent = event.target.result;
      let playlist = JSON.parse(fileContent);
      dispatch(setPlaylist(playlist.contents));
      // dispatch(setPlaylistName(playlist.name));
    }.bind(this);
    reader.readAsText(event.target.files[0]);
  }

  return (
    <React.Fragment>
      <IconButton 
        onClick={()=>fileInput.current.click()}
        className={classes.root}
      >
        <ImFolderUpload className={classes.icon}/>
      </IconButton>
      <input 
        className={classes.hidden} 
        type="file" 
        ref={fileInput} 
        onChange={loadFile}
      />
    </React.Fragment>
  );
}

export default UploadButton;