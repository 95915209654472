import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useStyles from './styles';
import makeSelectApp from '../App/selectors';
import makeSelectSpotifyPlaylists from './selectors';

import injectSaga from 'utils/redux/injectSaga';
import injectReducer from 'utils/redux/injectReducer';

import reducer from './reducer';
import saga from './saga';

import { getSpotifyPlaylists } from './actions';
import { TablePagination } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

const SpotifyPlaylists = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { spotifyToken } = useSelector(makeSelectApp());
  const { spotifyPlaylists, spotifyPlaylistsOffset, totalSpotifyPlaylists } = useSelector(makeSelectSpotifyPlaylists());

  useEffect(() => {
    dispatch(getSpotifyPlaylists());
  }, [JSON.stringify(spotifyToken)])

  return (   
    spotifyToken ? <div className={classes.root}>
      <div className={classes.header}>
        My Spotify Playlists
      </div>
      <div className={classes.playlistsList}>
        {spotifyPlaylists && spotifyPlaylists.map(playlist => 
          <div className={classes.playlist} key={playlist.id} onClick={()=>history.push(`/playlist/${playlist.id}`)}>
            {playlist.name}
          </div>)}
      </div>
      <TablePagination
        component="div"
        className={classes.pagination}
        rowsPerPageOptions={[20]}
        count={totalSpotifyPlaylists || 0}
        rowsPerPage={20}
        page={spotifyPlaylistsOffset / 20}
        onChangePage={(event, value) => dispatch(getSpotifyPlaylists(value*20))}
      />
    </div> : null
  );
}

const withReducer = injectReducer({ key: 'spotifyPlaylists', reducer });
const withSaga = injectSaga({ key: 'spotifyPlaylists', saga });

export default compose(withReducer, withSaga)(SpotifyPlaylists);
