import { fromJS } from 'immutable';
import { getRichCookie } from 'utils/storage'
import { defaultSettings } from 'utils/constants'

const shuffle = (shuffleMap) => {
  if (!shuffleMap) return null;
  for(let i = shuffleMap.length -1; i > 0; i--) {
    const j = Math.floor(Math.random()*(i+1));
    [shuffleMap[i], shuffleMap[j]] = [shuffleMap[j], shuffleMap[i]];
  }
  return shuffleMap
}

export const initialState = fromJS({
  currentSong: null,
  currentSource: null, 
  currentSourceMeta: null,
  currentIndex: 0,
  shuffle: false,
  shuffleMap: null,
  spotifyPlaylists: null,
  spotifyPlaylistsOffset: 0,
  totalSpotifyPlaylists: null,
  playing: false,
  hasWon: false,
  hasForfeited: false
 });

export default function controlPanelReducer(state = initialState, action) {

  switch (action.type) {
    case 'LOAD_NEXT_SONG': {
      const currentIndex = state.get('currentIndex')
      const currentSource = state.get('currentSource')
      const nextIndex = currentIndex < (currentSource.length - 1) ? (currentIndex + 1) : 0;
      return state.set('currentIndex', nextIndex);
    }
    case 'LOAD_PREV_SONG': {
      const currentIndex = state.get('currentIndex');
      const currentSource = state.get('currentSource');
      const prevIndex = currentIndex > 0 ? (currentIndex - 1) : (currentSource.length - 1);
      return state.set('currentIndex', prevIndex);
    }
    case 'SET_SOURCE': {
      const { shuffleOnLoad } = (getRichCookie("settings") || defaultSettings);
      const shuffleMap = action.source && action.source.map((value, index) => index);
      if (shuffleOnLoad) shuffle(shuffleMap);
      return state
        .set('currentSong', null)
        .set('currentIndex', 0)
        .set('currentSource', action.source)
        .set('currentSourceMeta', action.meta || { name: "Custom Playlist"})
        .set('shuffle', shuffleOnLoad)
        .set('shuffleMap', shuffleMap);
    }
    case 'GET_GENIUS_SONG': {
      const { playOnLoad } = (getRichCookie("settings") || defaultSettings);
      return state
        .set('currentSong', null)
        .set('playing', playOnLoad)
        .set('hasWon', false)
        .set('hasForfeited', false)
        .set('loading', true);
    }
    case 'GET_GENIUS_SONG_SUCCESS':
      return state
        .set('currentSong', action.song)
        .set('loading', false);
    case 'TOGGLE_SHUFFLE':
      const shuffleMap = state.get('currentSource').map((value, index) => index);
      if (!state.get('shuffle')) {
        shuffle(shuffleMap);
      }
      return state
        .set('shuffle', !state.get('shuffle'))
        .set('shuffleMap', shuffleMap );
    case 'TOGGLE_PAUSE':
      return state.set('playing', !state.get('playing'));
    case 'FORFEIT':
      return state.set('hasForfeited', true);
    case 'WIN':
      return state.set('hasWon', true);
    default:
      return state;
  }
}
