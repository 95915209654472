import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';

import injectSaga from 'utils/redux/injectSaga';
import injectReducer from 'utils/redux/injectReducer';

import SongList from 'components/SongList';

import reducer from './reducer';
import saga from './saga';
import { 
  getPlaylist,
} from './actions';
import useStyles from './styles';
import { setSourcePlaylist } from 'containers/ControlPanel/actions'

import { Button, TablePagination } from '@material-ui/core';


const selectPlaylist = createSelector(
  state => state.playlist,
  substate => substate.toJS()
);

const Playlist = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { tracks, trackOffset, totalTracks, loading, playlistMeta } = useSelector(selectPlaylist);


  useEffect(()=> {
    dispatch(getPlaylist(id))
  }, [id])

  return (
    <div className={classes.root}>
      {loading || tracks.length === 0 
        ? <div className={classes.loading}>Loading...</div> 
        : <div className={classes.innerRoot}>
          <div className={classes.outerPlaylistHeader}>
            <div className={classes.innerPlaylistHeader}>
              <div 
                className={classes.playlistArt} 
                style={{backgroundImage:`url(${playlistMeta.images && playlistMeta.images[0].url})`}}
                onClick={()=>dispatch(setSourcePlaylist(id, playlistMeta))}
              />
              <div className={classes.playlistInfo}>
                <div className={classes.playlistTitle}>
                  {playlistMeta.name}
                </div>
                {playlistMeta.owner && <div className={classes.playlistArtists}>
                  {playlistMeta.owner}
                </div>}
              </div>
            </div>
          </div>
          <SongList tracks={tracks}/>
          <TablePagination
            component="div"
            className={classes.pagination}
            rowsPerPageOptions={[20]}
            count={totalTracks || 0}
            rowsPerPage={20}
            page={trackOffset / 20}
            onChangePage={(event, value) => dispatch(getPlaylist(id, value*20))}
          />
          <div className={classes.filler}/>
        </div>}
    </div>
  );
}

const withReducer = injectReducer({ key: 'playlist', reducer });
const withSaga = injectSaga({ key: 'playlist', saga });

export default compose(withReducer, withSaga)(Playlist);
